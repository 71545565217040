import { Injectable } from '@angular/core';
import { fromEvent, merge, of, BehaviorSubject } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectionStatusService {

  readonly online$ = new BehaviorSubject<boolean>(true);

  constructor() {
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false)),
    ).subscribe((status) => {
      this.online$.next(status);
    });
  }

}
